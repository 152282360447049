import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex_column_container" }
const _hoisted_2 = { class: "ion-no-margin title" }

import {
  IonItem,
  IonText
} from '@ionic/vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'TitleWithSmallDescription',
  props: {
    title: {},
    description: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonItem), { lines: "none" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(IonText), { color: "dark" }, {
          default: _withCtx(() => [
            _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }),
        _createVNode(_unref(IonText), { color: "medium" }, {
          default: _withCtx(() => [
            _createElementVNode("small", null, _toDisplayString(_ctx.description), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})