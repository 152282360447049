import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import { IonApp, IonContent, IonButton, IonIcon, IonToolbar, IonButtons, IonText, IonFooter } from '@ionic/vue';
import {refreshOutline} from 'ionicons/icons';
import "@/styles/utils.scss"
import {onMounted, onUnmounted, ref, watch} from "vue";
import {localeStoreService} from "@/storage/StorageService";
import {useI18n} from "vue-i18n";
import {languages} from "@/i18n";
import EqifyHeader from '@/component/EqifyHeader.vue';
import {App} from "@capacitor/app";
import {PluginListenerHandle} from "@capacitor/core";
import ConfigView from "@/modules/config/ConfigView.vue";
import OfflineItem from "@/component/OfflineItem.vue";
import {online} from "@/http/connection-status";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppStartFailed',
  setup(__props) {

const i18n = useI18n()

let listener: PluginListenerHandle;

onMounted(async () => {
  const lang =  await localeStoreService.getLocale() || languages[0].lang;
  i18n.locale.value = lang.toString()

  listener = App.addListener('appStateChange', async state => {
   if (state.isActive) {
     reload();
   }
  });

  watch(online, (isOnline) => {
    if (isOnline) {
      reload();
    }
  });
});

onUnmounted(() => {
  if (listener) {
    listener.remove();
  }
});

function reload() {
  window.location.reload();
}

let holdStart: number;
let showConfig = ref(false);

function openConfigHoldStart() {
  holdStart = Date.now();
}

function openConfigHoldEnd() {
  const elapsed = Date.now() - holdStart;
  if (elapsed > 3000) {
    showConfig.value = true;
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      (_unref(showConfig))
        ? (_openBlock(), _createBlock(ConfigView, { key: 0 }))
        : _createCommentVNode("", true),
      (!_unref(showConfig))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(EqifyHeader, {
              onTouchstart: openConfigHoldStart,
              onTouchend: openConfigHoldEnd,
              onMousedown: openConfigHoldStart,
              onMouseup: openConfigHoldEnd,
              onDragenter: openConfigHoldStart,
              onDragleave: openConfigHoldEnd
            }),
            _createVNode(_unref(IonContent), { class: "ion-padding" }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonText), { color: "danger" }, {
                  default: _withCtx(() => [
                    _createElementVNode("h6", null, _toDisplayString(_ctx.$t("start.failed.message")), 1),
                    _createVNode(_unref(IonButton), {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (reload()))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonIcon), {
                          slot: "start",
                          icon: _unref(refreshOutline)
                        }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("start.failed.try_again")), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_unref(IonFooter), null, {
              default: _withCtx(() => [
                _createVNode(OfflineItem),
                _createVNode(_unref(IonToolbar), null, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonButtons), { slot: "end" }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonButton), {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (reload()))
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_unref(IonIcon), {
                              slot: "start",
                              icon: _unref(refreshOutline)
                            }, null, 8, ["icon"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})