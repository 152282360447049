import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonRouterOutlet,
  IonBadge,
  IonText
} from '@ionic/vue';
import {homeOutline, personOutline, cartOutline, searchOutline, trophyOutline} from 'ionicons/icons';
import {computed, onMounted, ref, Ref, watch} from "vue";
import {useKeycloak} from '@/authentication/vue-keycloak';
import OfflineItem from "@/component/OfflineItem.vue";
import {useCartStore} from "@/modules/payment/store/CartStore";
import 'animate.css';


export default /*@__PURE__*/_defineComponent({
  __name: 'TabsContent',
  setup(__props) {

let cartItemCount = ref(0)

const cartStore = useCartStore()
const {decodedToken}: { decodedToken: Ref } = useKeycloak() as any;
const keycloak = useKeycloak()

const isLoggedIn = computed((): boolean => {
  return keycloak.isAuthenticated.value
})

watch(isLoggedIn, async () => {
  await cartStore.get()
  cartItemCount.value = cartStore.items.length
  cartStore.$subscribe(() => {
    cartItemCount.value = cartStore.items.length
  }, {detached: true})
})

const firstname = computed(() => {
  if (!decodedToken || !decodedToken.value) {
    return undefined;
  }

  return decodedToken.value.given_name;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonTabs), { mode: "md" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonRouterOutlet)),
          _createVNode(OfflineItem),
          _createVNode(_unref(IonTabBar), {
            class: "eqify_tabbar",
            slot: "bottom",
            color: "medium"
          }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonTabButton), {
                class: "tabbar_item no-ripple",
                tab: "competition",
                href: "/tabs/competition"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("tabs.start")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonIcon), { icon: _unref(homeOutline) }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                class: "tabbar_item no-ripple",
                tab: "search",
                href: "/tabs/search"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("tabs.search")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonIcon), { icon: _unref(searchOutline) }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                class: "tabbar_item no-ripple",
                tab: "championship",
                href: "/tabs/championship"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonIcon), {
                    color: "warning",
                    icon: _unref(trophyOutline)
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                class: "tabbar_item no-ripple",
                tab: "cart",
                href: "/tabs/cart"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("tabs.cart")), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_unref(IonIcon), { icon: _unref(cartOutline) }, null, 8, ["icon"]),
                  (_unref(cartItemCount) !== 0 && isLoggedIn.value)
                    ? (_openBlock(), _createBlock(_unref(IonBadge), {
                        key: 0,
                        class: "animate__animated animate__heartBeat animate__infinite",
                        color: "danger"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(cartItemCount)), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_unref(IonTabButton), {
                class: "tabbar_item no-ripple",
                tab: "profile",
                href: "/tabs/profile"
              }, {
                default: _withCtx(() => [
                  (firstname.value)
                    ? (_openBlock(), _createBlock(_unref(IonLabel), { key: 0 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(firstname.value), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_unref(IonLabel), { key: 1 }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("tabs.profile")), 1)
                        ]),
                        _: 1
                      })),
                  _createVNode(_unref(IonIcon), { icon: _unref(personOutline) }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})