import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {
  IonItem
} from '@ionic/vue';
import {computed} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CardItem',
  props: {
    button: { type: Boolean },
    border: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const asButton = computed(() => {
  return props.button == undefined ? true : props.button
})

function classes() {
  if (props.border) {
    return `border-color`
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonItem), {
    lines: "none",
    class: _normalizeClass(`pl-2 pr-2 ${classes()}`),
    color: "light",
    button: asButton.value,
    mode: "ios"
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "content")
    ]),
    _: 3
  }, 8, ["class", "button"]))
}
}

})