import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { slot: "end" }

import {
  IonHeader,
  IonButtons,
  IonToolbar,
  IonBackButton,
  IonMenuButton,
} from '@ionic/vue';



export default /*@__PURE__*/_defineComponent({
  __name: 'EqifyHeader',
  props: {
  backgroundColor: String,
  enableBackButton: Boolean,
  absoluteHeader: Boolean
},
  setup(__props) {

const props = __props

function background() {
  return props.backgroundColor || 'transparant'
}

function hasAbsoluteHeader(): boolean {
  return props.absoluteHeader == true
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonHeader), { class: "ion-no-border" }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonToolbar), {
        mode: "ios",
        color: background(),
        class: _normalizeClass({'ion-no-margin': true, 'position-absolute': hasAbsoluteHeader() })
      }, {
        default: _withCtx(() => [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo-title" }, [
            _createElementVNode("img", {
              class: "eqifyBorder",
              src: "/eqify-logo-dark.svg"
            })
          ], -1)),
          _createVNode(_unref(IonButtons), { slot: "start" }, {
            default: _withCtx(() => [
              (__props.enableBackButton)
                ? (_openBlock(), _createBlock(_unref(IonBackButton), {
                    key: 0,
                    text: _ctx.$t('common.back'),
                    mode: "ios",
                    defaultHref: "/"
                  }, null, 8, ["text"]))
                : (_openBlock(), _createBlock(_unref(IonMenuButton), {
                    key: 1,
                    menu: "settings-menu",
                    mode: "ios"
                  }))
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "end")
          ])
        ]),
        _: 3
      }, 8, ["color", "class"])
    ]),
    _: 3
  }))
}
}

})