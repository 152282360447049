import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]




export default /*@__PURE__*/_defineComponent({
  __name: 'FlagComponent',
  props: {
    flag: {},
    size: {}
  },
  setup(__props: any) {

const props = __props

function getFlagUrl() {
  if (props.flag !== '' && props.flag !== undefined && props.flag !== null) {
    return "/assets/flags/" + props.flag.toLowerCase() + '.svg'
  } else {
    return "/assets/flags/unknown.svg"
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("img", {
      class: _normalizeClass(_ctx.size),
      src: getFlagUrl(),
      alt: "Language flag"
    }, null, 10, _hoisted_1)
  ]))
}
}

})