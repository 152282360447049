import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {IonLabel} from '@ionic/vue';
import {computed} from "vue";
import FlagComponent from "@/component/FlagComponent.vue";
import {useI18n} from "vue-i18n";
import {localeStoreService} from "@/storage/StorageService";
import {languages} from "@/i18n";
import CardItem from "@/component/CardItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LocaleSelect',
  setup(__props) {

const i18n = useI18n()

const currentLanguageIndex = computed(() => {
  const currentIndex = languages.findIndex(it => it.lang === i18n.locale.value);
  if (currentIndex > -1) {
    return currentIndex;
  }

  const fallbackIndex = languages.findIndex(it => it.lang === i18n.fallbackLocale.value)
  if (fallbackIndex > -1) {
    return fallbackIndex;
  }

  return 0;
});
const currentLanguage = computed(() => languages[currentLanguageIndex.value]);

function selectNext() {
  const newLanguage = languages[(currentLanguageIndex.value + 1) % languages.length];
  i18n.locale.value = newLanguage.lang;
  localeStoreService.saveLocale(currentLanguage.value.lang);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CardItem, { onClick: selectNext }, {
    content: _withCtx(() => [
      _createVNode(_unref(IonLabel), { slot: "start" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("settings.preferences.language")), 1)
        ]),
        _: 1
      }),
      _createVNode(_unref(IonLabel), { slot: "end" }, {
        default: _withCtx(() => [
          _createVNode(FlagComponent, {
            flag: currentLanguage.value.flag,
            size: "sm"
          }, null, 8, ["flag"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})