import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  alt: "p-a",
  src: "/assets/icon/favicon.png"
}
const _hoisted_3 = { class: "name_with_sub_item" }
const _hoisted_4 = {
  slot: "end",
  class: "text_italic"
}
const _hoisted_5 = {
  key: 1,
  slot: "end"
}
const _hoisted_6 = { slot: "end" }

import {
  IonContent,
  IonItem,
  IonAvatar,
  IonText,
  IonLabel, modalController
} from '@ionic/vue';

import {PersonMetaData} from "@/authentication/AuthenticationService";
import CardItem from "@/component/CardItem.vue";

enum RelationType {
  ME = "ME",
  MOTHER = "MOTHER",
  FATHER = 'FATHER',
  OTHER_FAMILY = "OTHER_FAMILY",
  ASSOCIATE = "ASSOCIATE"
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoggedInUserCheckProfileModal',
  props: {
    data: {},
    showMeOption: { type: Boolean },
    showOnlyMeOption: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

function allowedTypes(): Array<string> {
  if (props.showOnlyMeOption) return [RelationType.ME]
  else if (!props.showMeOption) return Object.keys(RelationType)
  else return Object.keys(RelationType).filter(it => it != 'ME')

}

function selectType(type: string) {
  modalController.dismiss({
    ref: props.data.ref,
    role: type
  }, 'select')
}

function selectNone() {
  modalController.dismiss({
    ref: props.data.ref,
    role: undefined
  }, 'select-none')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(CardItem, {
        class: "mt-3",
        lines: "none"
      }, {
        content: _withCtx(() => [
          _createVNode(_unref(IonAvatar), { slot: "start" }, {
            default: _withCtx(() => [
              (_ctx.data.avatar)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    alt: "p-a",
                    src: _ctx.data.avatar
                  }, null, 8, _hoisted_1))
                : (_openBlock(), _createElementBlock("img", _hoisted_2))
            ]),
            _: 1
          }),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => [
              _createElementVNode("h6", _hoisted_3, [
                _createElementVNode("span", null, _toDisplayString(_ctx.data.firstname) + " " + _toDisplayString(_ctx.data.lastname), 1)
              ])
            ]),
            _: 1
          }),
          _createElementVNode("small", _hoisted_4, _toDisplayString(_ctx.data.birthYear), 1)
        ]),
        _: 1
      }),
      _createVNode(_unref(IonItem), { lines: "full" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonLabel), { slot: "start" }, {
            default: _withCtx(() => [
              _createElementVNode("small", null, _toDisplayString(_ctx.$t('syncing.modal.profile.organisations')), 1)
            ]),
            _: 1
          }),
          (_ctx.data.organisations?.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.data.organisations, (club, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  slot: "end",
                  key: idx
                }, [
                  _createElementVNode("small", null, _toDisplayString(club), 1)
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.$t('syncing.modal.profile.none')), 1))
        ]),
        _: 1
      }),
      _createVNode(_unref(IonItem), { lines: "full" }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonLabel), { slot: "start" }, {
            default: _withCtx(() => [
              _createElementVNode("small", null, _toDisplayString(_ctx.$t('syncing.modal.profile.horse_count')), 1)
            ]),
            _: 1
          }),
          _createElementVNode("small", _hoisted_6, _toDisplayString(_ctx.data.practitionerCount), 1)
        ]),
        _: 1
      }),
      _createVNode(_unref(IonItem), {
        lines: "none",
        color: "dark"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => [
              _createElementVNode("small", null, _toDisplayString(_ctx.$t('syncing.modal.profile.select_type')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(allowedTypes(), (type, idx) => {
        return (_openBlock(), _createBlock(_unref(IonItem), {
          onClick: ($event: any) => (selectType(type)),
          button: "",
          key: idx
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.$t(`syncing.modal.profile.relation_types.${type}`)), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128)),
      _createVNode(_unref(IonItem), {
        button: "",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (selectNone()))
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonText), { color: "danger" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('syncing.modal.profile.unknown')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})