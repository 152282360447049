export const NL_SCHEMA = {
    common: {
        cancel: "Annuleren",
        add: "Toevoegen",
        me: "Ik",
        name: "Naam",
        horse: "Paard",
        rider: "Ruiter",
        presenter: "Voorsteller",
        loading_data: "Loading data..",
        back: "Terug",
        registration: "Inschrijving",
        close: "Sluiten",
        save: "Opslaan",
        search: "Zoeken..",
        show_all: "Toon alles"
    },

    horse: {
        gender: {
            MALE: "Male",
            STALLION: "Stallion",
            MARE: "Mare",
            GELDING: "Gelding",
            RIG: "Rig",
            FILLY_FOAL: "Filly/Mare Foal < 1y",
            COLT_FOAL: "Colt/Stallion Foal < 1y",
            FILLY: "Filly/Mare < 4y",
            COLT: "Colt/Stallion < 4y",
            FILLY_YEARLING: "Filly/Mare Yearling 1-2y",
            COLT_YEARLING: "Colt/Stallion Yearling 1-2y"
        }
    },

    error: {
        general: "Er ging iets mis.. (code: {code})",
        404: {
            title: "Niets gevonden",
            description: "We kunnen de door u gevraagde gegevens niet vinden"
        }
    },

    section: {
        GENERAL: "Algemeen",
        RANKING: "Klassementen"
    },

    status: {
        CANCELLED: "Geannuleerd"
    },

    discipline: {
        SHOW_JUMPING: "Springen",
        DRESSAGE: "Dressuur",
        STYLE_RIDING: "Stijlrijden",
        CROSS_COUNTRY: "Cross country",
        EVENTING: "Eventing",
        PRESENTATION: "Voorstelling"
    },

    tabs: {
        start: "start",
        championship: "kampioenschappen",
        search: "zoeken",
        cart: "mandje",
        profile: "profiel",
    },

    loader: {
        loading: "Ophalen van gegevens"
    },

    start: {
        header_tabs: {
            live: "Live",
            calendar: "Kalender",
            my_calendar: "Mijn kalender",
            channels: "Kanalen"
        },
        calendar: {
            upcoming: "Komend",
            today: "Vandaag",
            earlier: "Eerder",
            nextWeek: "Volgende week",
            lastWeek: "Vorige week",
            thisWeek: "Deze week",
            later: "Later",
            older: "Eerder"
        },
        my_calendar: {
            title: "Mijn kalender",
            description: "Bepaal hier hoe jouw kalender eruit ziet",
            disciplines: "Disciplines",
            channels: "Kanalen",
            championships: "Kampioenschappen",
            registration_only: "Toon enkel wedstrijden waarop kan ingeschreven worden"
        },
        failed: {
            message: "De app kon niet gestart worden, probeer later opnieuw.",
            try_again: "Opnieuw proberen",
        },
    },

    competition: {
        empty_list: "Nog geen wedstrijden beschikbaar",
        header_tabs: {
            register: "Inschrijven",
            program: "Programma",
            results: "Resultaten"
        },
        schedule: {
            empty: "Programma not niet beschikbaar"
        },
        person_input: {
            name_placeholder: "Naam van ruiter..",
        },
        organisation_input: {
            organisation: "Club",
            organisations: "Clubs",
            available_organisations: "Beschikbare clubs",
            organisation_placeholder: "Naam van club..",
            select: "Selecteer club",
            none_available: "Geen club beschikbaar",
        },
        horse_input: {
            name_placeholder: "Naam van paard..",
            ueln: "UELN",
            ueln_placeholder: "UELN van paard..",
            father: "Vader",
            father_placeholder: "Naam van vader..",
            mother: 'Moeder',
            mother_placeholder: "Naam van moeder..",
            father_of_mother: "Vader van moeder",
            father_of_mother_placeholder: "Naam vader van moeder..",
        },
        register: {
            label: "Inschrijven",
            warning: "Om te kunnen inschrijven moet je aangemeld zijn.",
            no_registration_tests: "Nog geen proeven beschikbaar",
            out_of_competition: "Buiten wedstrijd",
            for_sale: "Dit dier als 'Te Koop' aanbieden",
            individual: {
                label: "Inschrijven",
                description: "Formulier voor individuele inschrijving",
            },
            horseOnly: {
                label: "Inschrijven",
                description: "Formulier voor dier inschrijving",
            },
            person: {
                persons: "Personen",
                available_persons: "Beschikbare personen"
            },
            rider: {
                riders: "Ruiters",
                available_riders: "Beschikbare ruiters",
                select: "Ruiter selecteren",
                add: "Ruiter toevoegen",
                not_in_list: "Ruiter niet in de lijst?",
                none_available: "Geen ruiters beschikbaar"
            },
            horse: {
                horses: "Paarden",
                available_horses: "Beschikbare paarden",
                select: "Paard kiezen",
                add: "Paard toevoegen",
                not_in_list: "Paard niet in de lijst?",
                none_available: "Geen paarden beschikbaar",
                search: {
                    title: 'Zoek paarden',
                    empty: 'Zoek een paard..',
                    search_results: "Gevonden paarden",
                    no_search_results: "Geen paarden gevonden met naam:"
                },
                my_horses: "Mijn paarden",
                search_horses: "Zoeken",
                new_horse: "Nieuw",
                my_horses_info: "Overzicht van uw recent gebruikte paarden.",
                new_horse_info: "Voeg een nieuw paard toe als dit nog niet gekend is in Eqify."
            },
            validation: {
                modal: {
                    title: "Opgelet!",
                    description: "Er werden enkele (mogelijke) problemen gevonden"
                },
                with_warnings: "Met waarschuwingen"
            },
            competition: {
                label: "Wedstrijd",
            },
            test: {
                label: "Proef",
            },
            price: "Prijs",
            proceed: "Doorgaan",
            registration_added: "Inschrijving toegevoegd aan mandje!",
            person_cannot_be_registered: "Persoon kan niet worden ingeschreven.",
            free_combination: "Vrije combinatie",
            free_combination_description: "Vul onderstaande velden in",
            date: {
                beforeOpen: "Inschrijvingen zijn geopend vanaf:",
                isLate: "Laatste inschrijvingen mogelijk!",
                willCloseSoon: "Inschrijven sluit bijna!",
                isClose: "Inschrijvingen gesloten!",
                isOpen: "Inschrijvingen open!"
            }
        },
        startlist: {
            participant: "Deelnemer",
            points: "Punten",
            time: 'Tijd',
            expected: "Verwacht",
            excluded: "Niet geplaatst",
            rank: "Rang",
            pause: "Pauze",
            finishStatus: {
                FINISHED: "Finish",
                NOT_STARTED: "Niet gestart",
                ELIMINATED: "Uitgesloten",
                WITHDRAWN: "Terugtrekking",
                RETIRED: "Opgave",
                DISQUALIFIED: "Gediskwalificeerd",
                ERROR: "",
                INCOMPLETE: ""
            },
            detail: {
                header_tabs: {
                    result: "Resultaat",
                    pedigree: "Pedigree",
                    video: "Video"
                },
                video: {
                    empty: {
                        title: "Geen videos beschikbaar",
                        subtitle: "Er werden geen videos gevonden voor deze combinatie"
                    }
                }
            }
        },
        info: {
            general: "Algemeen",
            program: "Programma",
            visitors: "Bezoekers",
            vip: "VIP",
            trucks: "Vrachtwagens",
            trailers: "Aanhangers"
        }
    },

    championship: {
        detail: {
            competitions: {
                empty: {
                    title: "Geen wedstrijdresultaten",
                    description: "Bovenstaande punten zijn mogelijks afkomstig uit een selectie."
                }
            },
            fullList: "Toon volledig klassement"
        }
    },

    activePractitioner: "Deelnemer in piste",
    finishedPractitioner: "Laatst gefinished",
    expectedPractitioners: "Staan klaar",

    timekeeperState: {
        FINISHED: "Finish",
        COUNTDOWN: "Aftellen",
        RUNNING: "Bezig",
        WAITING: "Wachten"
    },

    timekeeperRunState: {
        PAUSE: "Pauze"
    },

    payment: {
        DEFERRED: "Betaling via uw federatie",
        DIRECT: "Online betaling",
        FREE: "Gratis",
        EXTERN: "Betaling ter plaatse",
        pay_now: "Te betalen",
        proceed: "Doorgaan",
        complete: "Bestelling met succes verwerkt!",

        amount: 'Bedrag',
        status: {
            name: "Status",
            SUCCEEDED: "Betaald",
            OPEN: "Niet betaald",
            FAILED: "Betaling Mislukt",
            UNKNOWN: "Niet betaald"
        }
    },

    cart: {
        title: "Mandje",
        description: "Inschrijvingen afrekenen",
        added_to_cart: "Toegevoegd aan mandje",
        empty: "Uw mandje is leeg",
        pay: "Betalen",
        continue_pay: "Alsnog betalen",
        checkout: "Bevestigen",
        continue_checkout: "Alsnog bevestigen",
        error: {
            REGISTRATION_IS_OUTSIDE_THE_ALLOWED_DATES: "De inschrijvingen voor deze proef zijn afgelopen",
            PRICE_PROFILE_CONFIGURATION_ERROR: "Er trad een probleem op bij deze inschrijving (PP-1)",
            PRICE_PROFILE_IS_INVALID: "Er trad een probleem op bij deze inschrijving (PP-2)",
            COMBINATION_REGISTRATION_NOT_ALLOWED: "Deze combinatie mag niet ingeschreven worden"
        }
    },

    auth: {
        general: {
            title: "Jouw account",
            subtitle: "Meld je aan om toegang te krijgen tot de volledige Eqify app"
        },
        login_or_register: "Inloggen of account aanmaken",
        login: "Inloggen",
        register: "Account aanmaken",
        new_user: "Heb je nog geen account op Eqify?",
        already_logged_in: "Gebruiker is reeds ingelogd",
        continue: "Ga verder",
        update_password: {
            title: "Wachtwoord wijzigen",
            new_password: "Nieuw wachtwoord",
            confirm_password: "Wachtwoord bevestigen",
            confirm_password_doesnt_match: "Bevestig wachtwoord is niet gelijk",
        },
        delete_account: {
            title: "Account verwijderen",
            info: "Weet u zeker dat u uw account met e-mailadres: <b>{email}</b> wilt verwijderen?",
            confirm_email: "Type uw email om te bevestigen",
            confirm_email_doesnt_match: "Bevestig email is niet gelijk",
            alert: {
                header: "Opgelet!",
                message: "Deze actie kan niet ongedaan worden gemaakt, weet je het zeker?",
            },
        },
        logout: {
            label: "Uitloggen",
            alert: {
                header: "Opgelet!",
                message: "U staat op het punt om uit te loggen, weet je het zeker?",
            },
        },
        pedigree: {
            title: "Aanmelden vereist",
            subtitle: "U moet aangemeld zijn om de Hippomundo pedigree te bekijken"
        }
    },

    profile: {
        my_registrations: {
            title: "Mijn inschrijvingen",
            description: "Overzicht van jouw inschrijvingen",
            empty: {
                title: "Geen inschrijvingen",
                description: "Al jouw inschrijvingen zullen hier terecht komen."
            },
            not_found: {
                title: "Geen zoekresultaten",
                description: "We konden geen wedstrijden vinden met jouw zoekopdracht"
            },
        },
        my_orders: {
            title: "Mijn bestellingen",
            description: "Overzicht van jouw bestellingen",
            order: "Bestelling",
            pay_online: "Online betalen",
            payment: {
                status: {
                    PAYED: "Betaald",
                    NOT_PAYED: "Niet betaald",
                }
            },
            empty: {
                title: "Geen bestellingen",
                description: "Al jouw bestellingen zullen hier terecht komen."
            },
        },
        my_bills: {
            title: "Mijn rekeningen",
            description: "Overzicht van jouw rekeningen",
            order: "Rekeningen",
            empty: {
                title: "Geen rekeningen",
                description: "Al jouw rekeningen zullen hier terecht komen."
            },
            status: {
                CANCELLED: "Geannuleerd",
                ORDERED: "Besteld",
                OPEN: "Open"
            },
            detail: {
                empty: {
                    title: "Geen items gevonden"
                }
            },
            type: {
                PERSONAL_BILL: "Persoonlijke rekening",
                COMPETITION_BILL: "Wedstrijd rekening",
                ORGANISATION_BILL: "Club rekening",
                MEMBER_BILL: "Lid rekening"
            }
        },
        my_accounts: {
            title: "Mijn adressen",
            account: "Adres",
            description: "Overzicht van jouw Adressen",
            empty: {
                title: "Geen adressen",
                description: "Al jouw adressen zullen hier terecht komen."
            },
            default: 'Standaard',
            default_action: "Dit is mijn standaard adres",
            create: {
                label: "Adres aanmaken",
                description: "Vul volgende velden in om een adres toe te voegen."
            },
            select: {
                label: "Adres selecteren",
                description: "Selecteer een adres uit onderstaande lijst"
            },
            form: {
                name: "Naam",
                type: {
                    label: "Type",
                    placeholder: "Selecteer een type",
                    PERSONAL: "Persoonlijk",
                    COMPANY: "Bedrijf"
                },
                phone: "Telefoon",
                email: "E-mail",
                iban: "IBAN",
                bic: "BIC",
                company_name: "Bedrijfsnaam",
                vat_number: "BTW-nummer",
                address: "Adres",
                street: "Straat",
                number: "Nummer",
                bus: "Bus",
                postal_code: "Postcode",
                city: "Stad",
                country: "Land",
                accountUsage: "Account gebruiken voor:",
                isCommunication: "Communicatie",
                isInvoicing: "Facturatie",
                defaultForCommunication: "Standaard voor communicatie",
                defaultForInvoicing: "Standaard voor facturatie"
            }
        },
        masterlist: {
            title: "Mijn starts",
            description: "Overzicht van jouw starttijden",
            empty: {
                title: "Geen starturen",
                description: "Al jouw starturen zullen hier terecht komen."
            },
            not_found: {
                title: "Geen zoekresultaten",
                description: "We konden geen wedstrijden vinden met jouw zoekopdracht"
            },
        },
        careers: {
            title: "Mijn carrières",
            description: "Overzicht van jouw carrières",
            empty: {
                title: "Geen carrières",
                description: "Al jouw carrières zullen hier terecht komen."
            },
            results: {
                title: "Resultaten",
                empty: {
                    title: "Geen resultaten",
                    description: "Geen resultaten gevonden voor deze carrière."
                },
            },
            history: {
                title: "Overgangen",
                empty: {
                    title: "Geen overgangen",
                    description: "Geen overgangen gevonden voor deze carrière."
                },
            }
        },
        rankings: {
            championship: {
                title: "Mijn klassementen",
                description: "Overzicht van jouw klassementen",
                empty: {
                    title: "Geen klassementen",
                    description: "Geen klassementen gevonden voor dit jaar"
                }
            }
        },
        horses: {
            title: "Mijn paarden",
            description: "Overzicht van jouw paarden",
            active: "Actief",
            empty: {
                title: "Geen paarden",
                description: "Geen paarden gevonden."
            }
        },
        videos: "Videos",
        rider: "Ruiter",
        horse: "Paard"
    },

    settings: {
        title: "Instellingen",
        account: {
            label: "Account gegevens",
            change: "Account wijzigen"
        },
        preferences: {
            label: "Voorkeuren",
            language: "Taal",
            push_notifications: "Push notificaties",
            email_notifications: "E-mail notificaties "
        },
    },

    finishStatus: {
        FINISHED: "Aangekomen",
        ELIMINATED: "Uitgeschakeld",
        RETIRED: "Opgave",
        WITHDRAWN: "Terugtrekking",
        DISQUALIFIED: "Gediskwalificeerd",
        NOT_STARTED: "Niet gestart"
    },

    homepage: {
        masterlist: {
            greeting: "Hallo",
            title: "Uw verwachte starts"
        }
    },

    empty: {
        arena: {
            title: "Geen proeven live",
            description: "Er zijn op dit moment geen proeven live."
        },
        details: {
            title: "Geen details beschikbaar"
        },
        cart: {
            title: "Uw mandje is leeg",
            description: "U kunt uw inschrijvingen hier afrekenen"
        },
        startlist: {
            title: "Startlijst is leeg",
            description: "Er zijn nog geen deelnemers voor deze startlijst"
        },
        information: {
            title: "Nog geen informatie",
            description: "Het is nog een beetje leeg hier"
        },
        live: {
            title: "Geen live evenementen",
            description: "Nog even geduld.."
        },
        events: {
            title: "Het is nog een beetje stil hier",
            description: "Binnenkort zijn er terug evenementen!"
        },
        my_calendar: {}
    },

    ranking: {
        total_points: "Totaalpunten",
        competitions: "Wedstrijden"
    },

    syncing: {
        success: "Account koppelen gelukt!",
        skip: 'Overslaan',
        modal: {
            title: "Account koppelen",
            description: "We hebben enkele bestaande Eqify-leden gevonden met jouw e-mailadres",
            info: "Selecteer jezelf en/of gerelateerde personen",
            sync: "Koppelen",
            profile: {
                organisations: 'Organisatie(s)',
                none: "Geen",
                horse_count: "Aantal paarden",
                select_type: "Ik ben..",
                unknown: "Ik ken deze persoon niet",
                relation_types: {
                    ME: "Dit ben ik",
                    MOTHER: "De moeder",
                    FATHER: "De vader",
                    OTHER_FAMILY: "Een ander familielid",
                    ASSOCIATE: "Een beheerder"
                }
            }
        }
    },

    hippomundo: {
        certified: "Certified by Hippomundo.com",
        powered: "Powered by Hippomundo.com"
    },

    search: {
        title: "Zoek hier..",
        info: {
            title: "Zoeken",
            subtitle: "Ga op zoek naar wedstrijden, ruiters en paarden"
        },
        recent: "Recente zoekopdrachten",
        loading: {
            title: "Aan het zoeken..",
            subtitle: "Even geduld"
        },
        no_results: {
            title: "Geen resultaten gevonden",
            subtitle: "We konden geen resultaten vinden met:"
        },
        competitions: "Wedstrijden",
        combinations: "Combinaties"
    }
}