import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  alt: "p-a",
  src: "/assets/icon/favicon.png"
}
const _hoisted_3 = { class: "text-muted name_with_sub_item" }
const _hoisted_4 = { class: "text_italic" }

import {
  IonItem,
  IonList,
  IonContent,
  IonLabel,
  IonIcon,
  IonText,
  IonFooter,
  IonButton,
  IonAvatar,
  modalController,
  IonToolbar,
  IonButtons,
  IonHeader
} from '@ionic/vue';
import {ref} from "vue";
import {checkmarkOutline} from 'ionicons/icons';
import {
  authenticationService,
  LoggedInUserCheck,
  LoggedInUserCheckConfirmation, LoggedInUserChild,
  PersonMetaData
} from "@/authentication/AuthenticationService";
import TitleWithSmallDescription from "@/component/TitleWithSmallDescription.vue";
import LoggedInUserCheckProfileModal from "@/component/LoggedInUserCheckProfileModal.vue";

export interface SelectedPerson {
  ref: string,
  role: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoggedInUserCheckModal',
  props: {
    data: {},
    email: {}
  },
  setup(__props: any) {

let selected = ref<Array<SelectedPerson>>([])

const props = __props

async function itemClicked(person: PersonMetaData) {
  const modal = await modalController.create({
    mode: 'ios',
    breakpoints: [0, 0.75, 1],
    initialBreakpoint: 0.75,
    component: LoggedInUserCheckProfileModal,
    componentProps: {
      data: person,
      showMeOption: hasMe(),
      showOnlyMeOption: props.data.persons.length == 1
    }
  })

  await modal.present()

  const modalResponse = await modal.onWillDismiss()

  if (modalResponse.role == 'select-none') {
    const existing = selected.value.find(it => it.ref == modalResponse.data.ref)
    if (existing) {
      const idx = selected.value.findIndex(it => it.ref == existing.ref)
      selected.value.splice(idx, 1)
    }
  }

  if (modalResponse.role == 'select') {
    const existing = selected.value.find(it => it.ref == modalResponse.data.ref)
    if (existing) {
      existing.role = modalResponse.data.role
    } else {
      selected.value.push({
        ref: modalResponse.data.ref,
        role: modalResponse.data.role
      })
    }
  }
}

function isSelected(ref: string): boolean {
  return selected.value.find(it => it.ref == ref) != undefined
}

function selectedPersonRole(ref: string): string {
  return selected.value.find(it => it.ref == ref)!.role
}

function hasMe(): boolean {
  return selected.value.find(it => it.role == 'ME') != undefined
}

function me(): SelectedPerson {
  return selected.value.find(it => it.role == 'ME')!
}

function close() {
  modalController.dismiss(null, 'cancel')
}

async function submit() {
  await authenticationService.userCheckConfirmation(
      props.email,
      {
        personRef: me().ref,
        relations: selected.value.filter(it => it.role != 'ME').map(it => {
          return {
            personRef: it.ref,
            relationType: it.role
          } as LoggedInUserChild
        })
      } as LoggedInUserCheckConfirmation
  )
  await modalController.dismiss(null, 'submit')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createVNode(_unref(IonHeader), null, {
          default: _withCtx(() => [
            _createVNode(_unref(IonToolbar), { color: "transparant" }, {
              default: _withCtx(() => [
                _createVNode(TitleWithSmallDescription, {
                  class: "pt-3 pb-2",
                  title: _ctx.$t('syncing.modal.title'),
                  description: _ctx.$t('syncing.modal.description')
                }, null, 8, ["title", "description"]),
                _createVNode(_unref(IonButtons), { slot: "end" }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonButton), {
                      color: "primary",
                      onClick: close
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('syncing.skip')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(IonItem), { lines: "none" }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonLabel), null, {
              default: _withCtx(() => [
                _createElementVNode("small", null, _toDisplayString(_ctx.$t('syncing.modal.info')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_unref(IonList), null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.persons, (person, idx) => {
              return (_openBlock(), _createBlock(_unref(IonItem), {
                onClick: ($event: any) => (itemClicked(person)),
                mode: "ios",
                lines: "full",
                class: "pl-1 pr-1 mb-2 personItemClass",
                key: idx
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(IonAvatar), { slot: "start" }, {
                    default: _withCtx(() => [
                      (person.avatar)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            alt: "p-a",
                            src: person.avatar
                          }, null, 8, _hoisted_1))
                        : (_openBlock(), _createElementBlock("img", _hoisted_2))
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_unref(IonLabel), null, {
                    default: _withCtx(() => [
                      _createElementVNode("h6", _hoisted_3, [
                        _createElementVNode("span", null, _toDisplayString(person.firstname) + " " + _toDisplayString(person.lastname), 1),
                        _createElementVNode("small", _hoisted_4, _toDisplayString(person.birthYear), 1)
                      ])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_unref(IonLabel), { slot: "end" }, {
                    default: _withCtx(() => [
                      (isSelected(person.ref))
                        ? (_openBlock(), _createBlock(_unref(IonText), {
                            key: 0,
                            color: "success"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(`syncing.modal.profile.relation_types.${selectedPersonRole(person.ref)}`)) + " ", 1),
                              _createVNode(_unref(IonIcon), { icon: _unref(checkmarkOutline) }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1024))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), { class: "background-dark" }, {
      default: _withCtx(() => [
        _createVNode(_unref(IonButton), {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (submit())),
          disabled: !hasMe(),
          class: "m-2 mb-4 mt-3",
          color: "tertiary",
          expand: "block"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('syncing.modal.sync')), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ]),
      _: 1
    })
  ], 64))
}
}

})