import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {
    IonItem,
    IonIcon,
    IonLabel,
} from '@ionic/vue';
import {cloudOfflineOutline} from 'ionicons/icons';
import {online} from "@/http/connection-status";

export default /*@__PURE__*/_defineComponent({
  __name: 'OfflineItem',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (!_unref(online))
    ? (_openBlock(), _createBlock(_unref(IonItem), {
        key: 0,
        color: "danger"
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(IonIcon), {
            slot: "start",
            icon: _unref(cloudOfflineOutline)
          }, null, 8, ["icon"]),
          _createVNode(_unref(IonLabel), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Offline")
            ])),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})