import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {IonApp, IonMenu, IonRouterOutlet, isPlatform, modalController, toastController} from '@ionic/vue';
import SettingsMenu from "@/component/SettingsMenu.vue";
import "@/styles/utils.scss"
import {computed, onMounted, Ref, watch} from "vue";
import {localeStoreService} from "@/storage/StorageService";
import {useI18n} from "vue-i18n";
import {useKeycloak} from "@/authentication/vue-keycloak";
import {authenticationService} from "@/authentication/AuthenticationService";
import LoggedInUserCheckModal from "@/component/LoggedInUserCheckModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const keycloak = useKeycloak()
const i18n = useI18n()
const {decodedToken}: { decodedToken: Ref } = keycloak as any;

const isLoggedIn = computed((): boolean => {
  return keycloak.isAuthenticated.value
})

watch(isLoggedIn, async () => {
  const email = keycloak.keycloak.tokenParsed?.preferred_username
  const response = await authenticationService.userCheck(email)

  if (response.needsPersonConfirmation) {
    const modal = await modalController.create({
      component: LoggedInUserCheckModal,
      componentProps: {
        data: response,
        email: decodedToken.value.given_name
      }
    })
    await modal.present()

    const modalResponse = await modal.onDidDismiss()

    if (modalResponse.role == 'submit') {
      const toast = await toastController.create({
        message: i18n.t("syncing.success"),
        color: 'success',
        position: 'top',
        buttons: [
          {
            text: 'ok',
            role: 'cancel'
          }
        ]
      })
      await toast.present()
    }
  }
})

onMounted(async () => {
  const lang = await localeStoreService.getLocale();
  if (lang) {
    i18n.locale.value = lang.toString()
  }
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonApp), null, {
    default: _withCtx(() => [
      (!_unref(isPlatform)('desktop'))
        ? (_openBlock(), _createBlock(_unref(IonMenu), {
            key: 0,
            side: "start",
            "menu-id": "settings-menu",
            "content-id": "main-content"
          }, {
            default: _withCtx(() => [
              _createVNode(SettingsMenu)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_unref(IonRouterOutlet), { id: "main-content" })
    ]),
    _: 1
  }))
}
}

})